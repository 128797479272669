import { createSlice } from '@reduxjs/toolkit';
import { IStatus } from '../../../@types/status';
import { getAllBookSales } from './actions';
var initialState = {
    bookSales: { docs: [], meta: {} },
    bookSale: null,
    status: IStatus.IDLE,
};
var slice = createSlice({
    name: 'bookSales',
    initialState: initialState,
    reducers: {},
    extraReducers: function (builder) {
        // // CREATE ONE
        // builder
        //   .addCase(createBookStock.pending, (state) => {
        //     state.status = IStatus.LOADING;
        //   })
        //   .addCase(createBookStock.fulfilled, (state, action) => {
        //     state.status = IStatus.SUCCEEDED;
        //     state.bookStocks = action.payload.data;
        //   })
        //   .addCase(createBookStock.rejected, (state) => {
        //     state.status = IStatus.FAILED;
        //   });
        // // UPDATE ONE
        // builder
        //   .addCase(updateBookStock.pending, (state) => {
        //     state.status = IStatus.LOADING;
        //   })
        //   .addCase(updateBookStock.fulfilled, (state, action) => {
        //     state.status = IStatus.SUCCEEDED;
        //     state.bookStock = action.payload.data;
        //   })
        //   .addCase(updateBookStock.rejected, (state) => {
        //     state.status = IStatus.FAILED;
        //   });
        // GET ALL
        builder
            .addCase(getAllBookSales.pending, function (state) {
            state.status = IStatus.LOADING;
        })
            .addCase(getAllBookSales.fulfilled, function (state, action) {
            state.status = IStatus.SUCCEEDED;
            state.bookSales = action.payload;
        })
            .addCase(getAllBookSales.rejected, function (state) {
            state.status = IStatus.FAILED;
        });
        // // GET ONE
        // builder
        //   .addCase(getOneBookStock.pending, (state) => {
        //     state.status = IStatus.LOADING;
        //   })
        //   .addCase(getOneBookStock.fulfilled, (state, action) => {
        //     state.status = IStatus.SUCCEEDED;
        //     state.bookStock = action.payload.data;
        //   })
        //   .addCase(getOneBookStock.rejected, (state) => {
        //     state.status = IStatus.FAILED;
        //   });
        // // DELETE ONE
        // builder
        //   .addCase(deleteOneBookStock.pending, (state) => {
        //     state.status = IStatus.LOADING;
        //   })
        //   .addCase(deleteOneBookStock.fulfilled, (state, action) => {
        //     state.status = IStatus.SUCCEEDED;
        //     state.bookStocks.docs = state.bookStocks.docs.filter(
        //       (bookStock) => bookStock._id !== action.meta.arg
        //     );
        //   })
        //   .addCase(deleteOneBookStock.rejected, (state) => {
        //     state.status = IStatus.FAILED;
        //   });
    },
});
export default slice.reducer;
